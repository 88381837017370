
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import PointsBanner from '@apps-orangefi/assets/images/banners/points-banner.svg'
import {
  useClaimableStrykeRewards,
  useClaimStrykeRewardsForm,
  useVaultList,
} from '@apps-orangefi/hooks'
import {
  targetChainAtom,
  selectedChainAtom,
  usdceAddressAtom,
  urlBaseAtom,
} from '@apps-orangefi/lib/store'
import { VaultInfo } from '@apps-orangefi/lib/types'
import { numberWithShortScale, addHex, genLinkChainAction } from '@apps-orangefi/lib/utils'
import { chainLogos, Stats } from '@apps-orangefi/ui/atoms'
import { SwitchButton } from '@apps-orangefi/ui/atoms/buttons'
import { getLayout } from '@apps-orangefi/ui/layouts'
import { RewardTable } from '@apps-orangefi/ui/organisms'
import { ProductListTemplate } from '@apps-orangefi/ui/templates'
import { useAtomValue, useSetAtom, useAtom } from 'jotai'
import { capitalize, chain as _chain, map } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useAccount } from 'wagmi'

import {
  getContractInfoListByChainId,
  USDCE_ADDRESSES,
  STRYKE_DISTRIBUTOR_ADDRESS,
  STRYKE_REWARD_TOKEN_ADDRESS,
} from '@/config'
import { NextPageWithLayout } from '@/pages/_app'

const LPDFiVaultListPage: NextPageWithLayout = () => {
  const router = useRouter()
  const { address: account }: { address: AddressType | undefined } = useAccount()
  const { chain } = router.query as { chain: string }

  const [vaultInfoList, setVaultInfoList] = useState<VaultInfo[]>([])
  const setUsdceAddress = useSetAtom(usdceAddressAtom)

  const { supportedChains, defaultChainId } = useAtomValue(targetChainAtom)
  const urlBase = useAtomValue(urlBaseAtom)
  // move to hooks
  const [selectedChain, setSelectedChain] = useAtom(selectedChainAtom)
  // Is defaultChainId optional?
  const chainId = selectedChain?.id ?? defaultChainId!

  const { allTvl, productCardList, myVaults, rewardPriceUsd, fetching } = useVaultList(
    account,
    vaultInfoList,
    chainId,
    STRYKE_DISTRIBUTOR_ADDRESS
  )

  const {
    vaultRewards,
    totalClaimableAmountUsd,
    claimParams,
    isFetching: isFetchingStrykeRewards,
    refetch: refetchStrykeRewards,
  } = useClaimableStrykeRewards(
    account,
    STRYKE_REWARD_TOKEN_ADDRESS,
    chainId,
    productCardList,
    rewardPriceUsd
  )

  const { onClaim, isClaimReady } = useClaimStrykeRewardsForm(
    STRYKE_DISTRIBUTOR_ADDRESS,
    claimParams,
    totalClaimableAmountUsd.gt(0),
    refetchStrykeRewards
  )

  // NOTE: this is app specific from config
  // const vaultInfoList = getContractInfoListByChainId(chainId)
  useEffect(() => {
    setVaultInfoList(getContractInfoListByChainId(chainId))
  }, [chainId])

  useEffect(() => {
    setUsdceAddress(USDCE_ADDRESSES[chainId])
  }, [chainId, setUsdceAddress])

  useEffect(() => {
    const supportedChain = supportedChains[chain]
    if (!!supportedChain) {
      setSelectedChain(supportedChain)
    }
  }, [chain])

  useEffect(() => {
    const isChainSupported = !!supportedChains[chain]
    if (!isChainSupported) {
      router.replace('/404')
      // return { notFound: true }
    }
  }, [chain, supportedChains])

  const onLinkChain = useMemo(
    () => genLinkChainAction(router, selectedChain),
    [router, selectedChain]
  )

  return (
    <div className="flex flex-col container py-8 md:py-16 ">
      <div>
        <div className="type-3xl-semibold dark:text-white">Liquidity Vaults</div>
        <div className="flex flex-row justify-between mt-5">
          <Stats
            label="Total Value Locked"
            value={`$${numberWithShortScale(allTvl)}`}
            className="w-full md:w-80"
          />
          {/* <a href={`${urlBase.points}/events/chapter2`} className="flex items-center">
            <Image src={PointsBanner} alt="points-banner" width={500} height={92} />
          </a> */}
          {/* <Stats
            label="Total Fee Revenue"
            value={`$${numberWithShortScale(totalFeeRevenue)}`}
            className="w-80 ml-3"
          /> */}
        </div>
      </div>
      {/* <RewardTable
        vaultRewards={vaultRewards}
        totalClaimableAmountUsd={totalClaimableAmountUsd}
        fetching={isFetchingStrykeRewards}
        isClaimable={isClaimReady}
        onClaimAll={onClaim}
      /> */}

      <div className="mt-9 md:mt-10">
        <ProductListTemplate
          prodcutCardList={productCardList}
          myVaults={myVaults}
          fetching={fetching}
        >
          <div className="flex flex-row mt-5 md:mt-6">
            {map(supportedChains, (chain, network) => (
              <SwitchButton
                label={capitalize(network)}
                logoUrl={chainLogos[chain.id]}
                isActive={chain === selectedChain}
                onClick={() => {
                  // router.push(`/${network}`)
                  onLinkChain(chain)
                }}
                key={chain.id}
              />
            ))}
          </div>
        </ProductListTemplate>
      </div>
    </div>
  )
}

LPDFiVaultListPage.getLayout = getLayout

export default LPDFiVaultListPage


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[chain]/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  